/* stylelint-disable scss/no-global-function-names */
/* stylelint-disable scss/at-mixin-pattern */
@use 'sass:map';
@use 'sass:math';

// breakpoints - up, down, only, between (use the included 'rs-bp' snippet)
@forward './breakpoints' with (
  $screens: (
    'small': 480px,
    'medium': 768px,
    'large': 1024px,
    'xlarge': 1280px,
    'xxlarge': 1440px,
  )
);

// rem and em helpers
@function rem($px) {
  @return math.div($px, 16px) * 1rem;
}

@function em($px) {
  @return math.div($px, 16px) * 1em;
}

// Spacings
$spacings: '50', '000', '100', '200', '300', '400', '500', '600', '700', '800';
$directions: 'top', 'left', 'right', 'bottom';
$properties: 'margin', 'padding';

@mixin spacings {
  @each $spacing in $spacings {
    @each $property in $properties {
      .#{$property}-#{$spacing} {
        #{$property}: var(--spacing-#{$spacing}) !important;
      }

      @each $direction in $directions {
        .#{$property}-#{$direction}-#{$spacing} {
          #{$property}-#{$direction}: var(--spacing-#{$spacing}) !important;
        }
      }
    }
  }

  @each $property in $properties {
    .no-#{$property} {
      #{$property}: 0 !important;
    }

    @each $direction in $directions {
      .no-#{$property}-#{$direction} {
        #{$property}-#{$direction}: 0 !important;
      }
    }
  }
}

@mixin aspect-ratio($w, $h) {
  aspect-ratio: #{$w} / #{$h};

  @supports not (aspect-ratio: #{$w} / #{$h}) {
    &::before {
      content: '';
      float: left;
      padding-top: math.div($h, $w) * 100%;
    }

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }
}

// Links
@mixin color($color, $hover) {
  color: $color;

  &:hover {
    color: $hover;
  }
}

// Button
@mixin button($bgClr, $txtClr) {
  background-color: $bgClr;
  border-color: $bgClr;
  color: $txtClr !important;

  &:hover {
    background-color: darken($bgClr, 6);
    border-color: darken($bgClr, 6);
  }

  &:focus {
    background-color: darken($bgClr, 9);
    border-color: darken($bgClr, 9);
  }
}

@mixin buttonLine($brdClr, $txtClr) {
  border-color: $brdClr !important;
  color: $txtClr;
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 0%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $brdClr;
    @include transitions(700);
  }

  &:hover,
  &:focus {
    border-color: $brdClr;
    color: #fff !important;

    &::after {
      width: 100%;
    }
  }
}

@mixin buttonSolid($bgClr, $txtClr) {
  background-color: $bgClr;
  color: $txtClr !important;
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 0%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $bgClr;
    @include transitions(700);
  }

  &:hover,
  &:focus {
    background-color: $bgClr;
    color: #fff !important;

    &::after {
      width: 100%;
    }
  }
}

// Transitions
@mixin transitions($num) {
  transition: #{$num}ms cubic-bezier(0.17, 0.67, 0, 1.01);
}

// Reveal Animation
@mixin reveal($height, $bgClr) {
  > * {
    display: block;
    width: 100%;
    height: #{$height}px;
    animation-name: slideTop;
    transition: 1000ms cubic-bezier(0.17, 0.67, 0, 1.01);
    transform: translateY(100%);
    animation-fill-mode: both;
    position: relative;
    z-index: -1;
    overflow: hidden;
    opacity: 0;

    &::before {
      content: '';
      display: block;
      position: absolute;
      height: #{$height}px;
      width: 100%;
      top: 0;
      bottom: 0;
      background: $bgClr;
      z-index: 1;
      transition: 1000ms cubic-bezier(0.17, 0.67, 0, 1.01);
      transform: translateY(100%);
    }
  }

  &.aos-animate {
    > * {
      transform: translateY(0);
      opacity: 1;

      &::before {
        transform: translateY(-100%);
      }
    }
  }
}
