/* stylelint-disable font-family-name-quotes */
// Figtree
@font-face {
  font-family: 'Figtree';
  src: url('../fonts/Figtree/Figtree-Light.woff2') format('woff2'), url('../fonts/Figtree/Figtree-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree';
  src: url('../fonts/Figtree/Figtree-Bold.woff2') format('woff2'), url('../fonts/Figtree/Figtree-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree';
  src: url('../fonts/Figtree/Figtree-Regular.woff2') format('woff2'), url('../fonts/Figtree/Figtree-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree';
  src: url('../fonts/Figtree/Figtree-SemiBold.woff2') format('woff2'), url('../fonts/Figtree/Figtree-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree';
  src: url('../fonts/Figtree/Figtree-Medium.woff2') format('woff2'), url('../fonts/Figtree/Figtree-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// Pixieicons
@font-face {
  font-family: 'Pixeicons';
  src: url('../fonts/Pixeicons/Main/Pixeicons.eot?4ts25x');
  src: url('../fonts/Pixeicons/Main/Pixeicons.eot?4ts25x#iefix') format('embedded-opentype'),
    url('../fonts/Pixeicons/Main/Pixeicons.ttf?4ts25x') format('truetype'),
    url('../fonts/Pixeicons/Main/Pixeicons.woff?4ts25x') format('woff'),
    url('../fonts/Pixeicons/Main/Pixeicons.svg?4ts25x#Pixeicons') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Pixeicons';
  src: url('../fonts/Pixeicons/Brands/Pixeicons.eot?4ts25x');
  src: url('../fonts/Pixeicons/Brands/Pixeicons.eot?4ts25x#iefix') format('embedded-opentype'),
    url('../fonts/Pixeicons/Brands/Pixeicons.ttf?4ts25x') format('truetype'),
    url('../fonts/Pixeicons/Brands/Pixeicons.woff?4ts25x') format('woff'),
    url('../fonts/Pixeicons/Brands/Pixeicons.svg?4ts25x#Pixeicons') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Pixeicons';
  src: url('../fonts/Pixeicons/Line/Pixeicons.eot?4ts25x');
  src: url('../fonts/Pixeicons/Line/Pixeicons.eot?4ts25x#iefix') format('embedded-opentype'),
    url('../fonts/Pixeicons/Line/Pixeicons.ttf?4ts25x') format('truetype'),
    url('../fonts/Pixeicons/Line/Pixeicons.woff?4ts25x') format('woff'),
    url('../fonts/Pixeicons/Line/Pixeicons.svg?4ts25x#Pixeicons') format('svg');
  font-weight: 300;
  font-style: normal;
}
