/* stylelint-disable */
/* Global */
html,
body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  min-height: 100%;
}

/* Body */
body {
  margin: 0;
  padding: 0;
  color: $black;
}

/* Links */
a,
a:hover {
  text-decoration: none;
  @include color($secClr, $mainClr);
}

/* Headings */
h1,
.uk-h1,
h2,
.uk-h2,
h3,
.uk-h3,
h4,
.uk-h4,
h5,
.uk-h5 {
  color: $headingClr;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}
.no-margin-top {
  margin-top: 0 !important;
}

.no-before {
  &::before {
    display: none !important;
  }
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  overflow: hidden;
  fill: currentColor;
  stroke: none;
  pointer-events: none;
}

.hide-mobile {
  display: none;

  @include up('medium') {
    display: inline-block;
  }
}

.hide-desktop {
  display: inline-block;

  @include up('medium') {
    display: none;
  }
}

.hidden {
  display: none !important;
}

.accordion-above-title {
  text-transform: uppercase;
  color: $mainClr;
  font-size: 1rem;
  line-height: 1.5rem;
}

.light {
  color: white !important;
}

.camelcase {
  text-transform: none !important;
}
