/* Table of content
---------------------------------------------------
00. Vendors
01. Body
02. Typogaphy
03. Header
04. Sections
05. Inner Pages
06. Footer
07. Utilities
08. Responsive
--------------------------------------------------- */

@use '../../components/cookie-consent/cookie-consent';

@import 'fonts';
@import 'variables';

/* 00. Vendors
--------------------------------------------------- */
@import 'vendors/normalize.min.css';
@import 'vendors/pr.animation.css';
@import 'vendors/uikit.min.css';
@import 'vendors/pixeicons.css';

@import 'mixins';

/* 01. Body
--------------------------------------------------- */
@import 'body';

/* 02. Typography
--------------------------------------------------- */
@import 'typography';

/* 03. Header
--------------------------------------------------- */
@import 'components/header';

/* 04. Sections
--------------------------------------------------- */
@import 'components/sections';

/* 05. Inner Pages
--------------------------------------------------- */
@import 'components/blog';
@import 'components/post';
@import 'components/page';
@import 'components/sidebar';

/* 06. Footer
--------------------------------------------------- */
@import 'components/footer';

/* 07. Utilities
--------------------------------------------------- */
@import 'utilities';

/* 08. Responsive
--------------------------------------------------- */
@import 'responsive';
