// Color Palette
$mainClr: #4800d8;
$mainClrLight: #6d48ff;
$secClr: #101010;
$black: #000;
$graydark: #747474;
$gray: #acacac;
$graylight: #e0e0e0;
$light: #f3f3f3;
$white: #fff;
$alternate: #dbe2e2;

// Font Types
$mainFnt: Figtree, sans-serif;
$secFnt: Figtree, sans-serif;

// Used Colors
$bodyClr: $graydark;
$headingClr: $secClr;

// Footer
$footerTxt: $graydark;
$footerLnk: $white;
$footerBg: #1b1b1b;
$footerCtrBg: #161616;
$footerBtmBg: $black;

// Light Footer
// $footerTxt: $graydark;
// $footerLnk: $secClr;
// $footerBg: #fff;
// $footerCtrBg: #fafafa;
// $footerBtmBg: #fff;
